.logo
{
	margin: 0;
	
	span
	{
		&:nth-of-type(1)
		{
			color: #85B542;
		}
		
		&:nth-of-type(2)
		{
			color: black;
		}
	}
}