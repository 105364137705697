body#login
{
	section#body-overlay-form
	{
		position:         fixed; /* Sit on top of the page content */
		display:          none; /* Hidden by default */
		width:            100%; /* Full width (cover the whole page) */
		height:           100%; /* Full height (cover the whole page) */
		top:              0;
		left:             0;
		right:            0;
		bottom:           0;
		background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
		z-index:          2; /* Specify a stack order in case you're using a different order for other elements */

		.form-send-password-reset-link
		{
			background-color: white;
			padding:          50px;
			position:         absolute;
			top:              50%;
			left:             50%;
			transform:        translate(-50%, -50%);
			-ms-transform:    translate(-50%, -50%);
			margin:           0 0 20px;

			a
			{
				margin-right: 50px;
			}

			input
			{
				margin-bottom: 20px;
			}

			.form-buttons
			{
				display:         flex;
				justify-content: space-between;

				input
				{
					margin: 0;
				}
			}
		}
	}

	section.content
	{
		flex-basis:      100%;
		height:          auto;
		display:         flex;
		justify-content: center;
		align-items:     center;
		align-content:   center;

		.form-wrapper
		{
			flex-basis: 100%;
			padding:    30px;
			max-width:  500px;

			form
			{
				.error-description
				{
					height: 20px;
				}
			}
		}
	}
}
