.item-wrapper
{
	padding: 0;

	.col-left
	{
		// margin-bottom: 16px;
		img
		{
			border-radius: 5px;
		}
	}

	.col-right
	{
		// margin-bottom: 16px;
		.item-top
		{
			display:         flex;
			justify-content: space-between;

			.name
			{
				color:  @secondary_color;
				margin: 0;
			}

			button
			{
				padding: 0 12px;
			}
		}

		.location_address
		{
			color:     gray;
			font-size: 14px;
			margin:    -5px 0 0;
		}

		.description-wrapper
		{
			flex-grow: 1;
			margin:    0;

			.description
			{
				max-height: 95px;
				overflow:   hidden;
				margin:     0;
			}
		}

		.price
		{
			display:     flex;
			align-items: flex-end;
			font-size:   18px;
			margin:      0;

			span, strong
			{
				max-height:   18px;
				margin-right: 4px;
			}
		}
	}
}
