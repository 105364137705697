body#user-registration
{
	section
	{
		flex-grow:       1;
		height:          auto;
		display:         flex;
		justify-content: center;
		align-items:     center;
		align-content:   center;
		margin-top:      150px;

		.form-wrapper
		{
			padding: 30px;
			width:   500px;

			form
			{
				.page-heading
				{
					margin-bottom: 20px;
					text-align:    center;
				}

				.error-description
				{
					height: 20px;
				}

				.buttons-wrapper
				{
					display:         flex;
					flex-wrap:       wrap;
					justify-content: space-between;

					button
					{
						flex-basis: 45%;

						&:nth-of-type(1)
						{
							flex-basis:    100%;
							margin-bottom: 15px;
						}
					}
				}
			}
		}
	}

	// .form-wrapper{
	//   flex-grow: 1;
	//   height: auto;
	//   display: flex;
	//   justify-content: center;
	//   align-items: center;
	//   align-content: center;
	//   form{
	//     padding-bottom: 200px;
	//     .page-heading{
	//       margin-bottom: 30px;
	//     }
	//   }
	// }
}
