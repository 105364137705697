a:hover
{
	color:           inherit;
	text-decoration: none;
}

*
{
	box-sizing: border-box;
}

*, *:hover, *:focus, *:active
{
	text-decoration: none;
	outline: 0;
}

@media only screen and (max-width: 3500px) and (min-width: 2500px)
{
	body
	{
		zoom: 125%;
	}
}

@media only screen and (max-width: 4100px) and (min-width: 3501px)
{
	body
	{
		zoom: 175%;
	}
}
