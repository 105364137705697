.search-app {
  position: relative;
}

#auto-complete {
  z-index: 9999;
  position: absolute;
  top: 72px;
  //left: 1px;
  min-width: 420px;
  background: #fff;
  background-clip: border-box;
  border-radius: 2px;
  box-shadow: 0 0 6px rgba(0,0,0,0.64);
  .items {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      > svg {
        position: absolute;
        left: 10px;
        bottom: 10px;
      }
      &.heading {
        position: relative;
        border-top: 1px solid #cfcfd4;
        background-color: #f0f0f0 !important;
        color: #666;
        margin: 9px 0 1px 0;
        padding: 10px 0 8px 41px;
        box-shadow: inset 0 1px #fff;
        -webkit-box-shadow: inset 0 1px #fff;
        -moz-box-shadow: inset 0 1px #fff;
        background: no-repeat 15px center;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 12px;
        &:first-child {
          margin-top: 0;
          border-top: none;
        }
      }
      &.item {
        font-weight: 300;
        &.selected {
          a {
            color: white;
            background-color: #4879a3;
            text-decoration: none;
            -webkit-box-shadow: 0 1px #9db8ce;
          }
        }
      }

      a {
        position: relative;
        display: block;
        line-height: 1.23;
        padding: 6px 12px 6px 41px;
        &:hover {
          color: white;
          background-color: #4879a3;
          text-decoration: none;
          -webkit-box-shadow: 0 1px #9db8ce;
        }
      }

    }
  }
}