body#homepage
{
	section
	{
		.carousel
		{
			overflow-y: hidden;

			.carousel-item
			{
				height: 500px;
				width:  100vw;

				.overlay
				{
					align-items:      center;
					display:          flex;
					flex-direction:   column;
					justify-content:  center;
					position:         absolute;
					width:            100%;
					height:           100%;
					background-color: rgba(0, 0, 0, 0.5);
					right:            0;
					bottom:           0;
					left:             0;
					#search_form
					{
					
					}
					//form
					//{
					//	display: flex;
					//
					//	.input-wrapper
					//	{
					//		background-color: white;
					//		padding:          15px;
					//		flex-grow:        1;
					//	}
					//
					//	.input-wrapper.submit
					//	{
					//		display:     flex;
					//		align-items: flex-end;
					//	}
					//}
				}

				img
				{
					top:      -200px;
					position: absolute;
					width:    100%;
					height:   auto;
				}
			}
		}

		.description
		{
			font-size:  20px;
			margin:     0 25%;
			text-align: center;
			padding:    20px;
		}

		.description-title
		{
			margin:      0;
			font-weight: 600;
		}
	}
}
