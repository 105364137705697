body
{
	section.content
	{
		.navigation-wrapper
		{
			background-color: @primary_color;
			margin-bottom:    16px;
			padding:          8px 16px 10px;
			*
			{
				color: white;
			}
			.navigation
			{
				height:    24px;
				font-size: 18px;
				margin:    0;

				.nav-item.not_first
				{
					&::before
					{
						content:      "»";
						margin-right: 6px;
					}
				}
			}
		}
		.location-items-heading
		{
			height:    24px;
			font-size: 24px;
			margin:    0;
		}

		.location-item
		{
			margin: 0 0 8px;
		}

		hr.hr-black
		{
			border-color: black;
		}
	}
}
