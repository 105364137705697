body
{
	overflow-x: hidden;
	#wrapper
	{
		&.toggled #sidebar-wrapper .list-group
		{
			display: block;
		}
		#sidebar-wrapper
		{
			#sidebar
			{
				//position: sticky;
				//top: 0;
				//left: 0;
				border-bottom: 3px solid @secondary_color;
				.sidebar-heading-wrapper
				{
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 0.875rem 1.25rem;
					.sidebar-heading
					{
						margin: 0;
					}
					#menu-toggle
					{
						display: flex;
						align-items: center;
						i
						{
							font-size: 24px;
						}
					}
				}
			}
			.list-group
			{
				display: none;
				width: 100%;
				span.link-icon-wrapper
				{
					display: inline-flex;
					justify-content: center;
					width: 20px;
					margin: 0 8px 0 0;
				}
				.custom-navbar-toggler
				{
					display: flex;
					align-items: center;
					width: 100%;
					padding: 12px 20px;
					cursor: pointer;
					&:hover
					{
						background-color: #dae0e5 !important;
					}
					span.link-text
					{
						flex: 1 1 auto;
					}
				}
				.dropdown-menu-item-content
				{
					display: none;
					.list-group-item
					{
						padding: 12px 20px 12px 40px;
					}
				}
				input[type=checkbox]:checked + .dropdown-menu-item-content
				{
					display: block;
				}
			}
		}
		#page-content-wrapper
		{
			padding: 16px;
			min-width: 100vw;
			min-height: 100vh;
			background-color: #E0EDF3;
			> .container-fluid
			{
				padding: 16px;
				background-color: #FFFFFF;
				height: 100%;
				h1
				{
					margin: 0 0 16px;
				}
			}
		}
	}
}

@media (min-width: 768px)
{
	body
	{
		#wrapper
		{
			display: flex;
			#sidebar-wrapper
			{
				min-height: 100vh;
				#sidebar
				{
					margin-left: 0;
					border: none;
					width: 320px;
					.sidebar-heading-wrapper
					{
						#menu-toggle
						{
							display: none;
						}
					}
					.list-group
					{
						display: block;
					}
				}
			}
			#page-content-wrapper
			{
				padding: 24px 48px;
				min-width: 0;
				width: 100%;
			}
		}
	}
	
	//#wrapper.toggled #sidebar-wrapper
	//{
	//	margin-left: -15rem;
	//}
}
