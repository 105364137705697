body#password-reset
{
	section
	{
		flex-grow:       1;
		height:          auto;
		display:         flex;
		justify-content: center;
		align-items:     center;
		align-content:   center;
		padding-bottom:  200px;

		.form-wrapper
		{
			padding: 30px;
			width:   500px;

			form
			{
				.page-heading
				{
					margin-bottom: 20px;
					text-align:    center;
				}

				.error-description
				{
					height: 20px;
				}
			}
		}
	}
}
