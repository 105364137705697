#main_navbar
{
	padding: 0;
	border-bottom: 3px solid @secondary_color;
	flex-flow: row wrap;
	justify-content: space-between;
	.logo
	{
		margin: 16px;
		background-color: transparent;
	}
	div.navbar-links
	{
		max-width: 768px;
		display: flex;
		background-color: transparent;
		> a
		{
			flex: 1 1 auto;
			padding: 16px;
			color: @secondary_color;
		}
	}
}
