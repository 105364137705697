body#message
{
	.message-wrapper
	{
		margin-top: 225px;
		flex-grow:  1;
		height:     auto;

		> *
		{
			text-align: center;
		}
	}
}
