
html
{
	height:      100%;
	font-family: @primary_font;

	body
	{
		display:     flex;
		flex-wrap:   wrap;
		height:      100%;
		align-items: stretch;
		flex-flow:   column nowrap;

		section.content
		{
			position: relative;
			flex:     1 1 auto;

			section.content
			{


			}
		}
	}

	@media only screen and (max-width: 1139px) and (min-width: 720px)
	{
		zoom: 80%;
	}
}
