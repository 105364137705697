.page-footer
{
	//flex-basis:       100%;
	//align-self:       flex-end;
	background-color: white;
	// border-top: 2px solid @secondary_color;
	.links-wrapper
	{
		> a
		{
			font-size: 14px;
			margin: 0 0 12px;
			color: @secondary_color;
		}
		//color: white;
		.footer-list-heading
		{
			font-size: 20px;
			font-weight: 600;
			margin: 0 0 14px;
		}
	}
}
